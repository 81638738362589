$cinza: rgb(37, 37, 37);
$roxo: rgb(88, 25, 102);
$amarelo: rgb(244, 174, 39);
$vermelho: rgb(230, 72, 47);
$bg: $cinza;
$preto: rgb(0,0,0);
$preto-claro: rgb(42,42,42);
$branco: rgb(255,255,255);
// $branco: rgb(228, 203, 203);;
$facebook: rgb(59,89,152);
$twitter: rgb(85,172,238);
$google: rgb(255,255,255);
$medium: rgb(0,171,107);
* { box-sizing: border-box; }