@import "colors";
@import "fonts";
@import "screens";
@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';

// FONTS
$artely: "artely", cursive;
$inter: "Inter", sans-serif;
//

/* Styles */
*,
html,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: $inter;
}

body {
  background-color: rgb(56 13 66);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {height: 100%;}
body {
  min-height:100%;
  margin: 0;
  padding: 0;
}
body{ position:relative; }

// BODY GRAIN EFFECT
body::before{
  content: "";
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 600'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
  background-repeat: repeat;
  background-size: 182px;
  opacity: 0.30;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

// NAVBAR
.nav-content {
  height: 65px;
  backdrop-filter: blur(10px);
  left: 0px;
  overflow: hidden;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9;

  nav::after{
    background-color: $branco;
    bottom: 0px;
    content: "";
    display: block;
    height: 1px;
    left: 20px;
    opacity: 0.4;
    position: absolute;
    width: calc(100% - 40px);
  }

  ul {
    margin: 0px auto;
    text-align: center;
    padding: 0;

    li {
      display: inline-block;
      margin: 20px 3%;

      a {
        background: none;
        border: none;
        color: $branco;
        display: inline-block;
        font-family: $inter;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        padding: 0px;
        position: relative;
        text-transform: uppercase;
        vertical-align: bottom;
        white-space: nowrap;
        letter-spacing: 0.1rem;
        text-decoration: none;

        &::before {
          display: block;
          left: 0;
          position: absolute;
          background-color: #fff;
          bottom: 0;
          content: "";
          height: 1px;
          opacity: .5;
          transform: scaleX(.0001);
          transform-origin: 0 0;
          transition: -webkit-transform .7s cubic-bezier(.645,.045,.355,1);
          transition: transform .7s cubic-bezier(.645,.045,.355,1);
          transition: transform .7s cubic-bezier(.645,.045,.355,1),-webkit-transform .7s cubic-bezier(.645,.045,.355,1);
          width: 100%;
        }

        &:hover:before {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
  }
}

// INTRO
canvas {
  touch-action: auto!important;
}
#c {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 800px 25px rgba(0,0,0,1) inset;
  clip-path: inset(0px -15px 0px -15px);
}
#bode {
  height: 100vh;
}
#intro {
  .inside {
    opacity: 0;
    left: 50%;
    max-width: 1300px;
    position: absolute;
    top: 50vh;
    transform: translate(-50%,-50%);
    width: 100%;
    text-align: center;

    img {
      @media (max-width: 900px){
        width: 90%;
      }
    }
  }
}

// CLIENTES
.clientes {
  position: relative;
  background: $preto;
  padding: 30px 0;
  z-index: -1;

  @media (min-width: $screen-sm-min) {
    padding: 80px 0;
  }

  h3.arte {
    font-family: $inter;
    color: $branco;
    font-size: 26px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
    font-style: italic;
    letter-spacing: 0.3rem;
    opacity: 0;

    span {
      font-size: 125%;
      text-transform: none;
      font-weight: normal;
      color: $amarelo;
      font-family: $artely;
    }

    @media (min-width: $screen-sm-min) {
      font-size: 36px;
    }
  }

  .grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    text-align: center;
    margin: auto;

    @media (min-width: $screen-sm-min) {
      grid-template-columns: repeat(6,1fr);
    }

    img {
      max-width: 90px;
      display: flex;
      align-self: center;
      justify-content: center;
      margin: .7vw auto 0;

      @media (min-width: $screen-sm-min) {
        max-width: 8.2vw;
        max-height: 4.7vw;
      }
    }
  }
}

// PROJETOS
.projetos {
  background: $preto;
  text-align: center;
  padding-top: 30px;

  h3.title {
    display: inline-block;
    font-family: $inter;
    color: $branco;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.3rem;
    border: 2px solid $branco;
    border-radius: 50px;
    padding: 10px 20px;
    opacity: 0;
  }

  .project-container {
    width: 90%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;

    .project-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      justify-content: center;

      .project-item {
        max-width: 50%;

        @media (min-width: $screen-sm-min) {
          max-width: 30%;
          &:nth-last-child(-n+2) {
            max-width: 45%;
          }
        }

        .grid-wrapper {
          width: 100%;
          padding: 1.2em;

          .grid-item {
            border-radius: 10px;
            background-color: transparent;
            opacity: 0;

            .thumb-wrap {
              width: 100%;
              position: relative;
              overflow: hidden;

              .image-item {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 10px;
              }
              .project-name {
                position: absolute;
                bottom: 0;
                padding: 10px;
                margin: 0;
                background: rgb(0 0 0 / 65%);
                color: #fff;
                width: 100%;
                text-align: left;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                font-weight: bold;
                font-size: 11px;
                letter-spacing: 0.02rem;
              }
            }
          }
        }
      }
    }
  }
}

// CONTATO
.contato {
  background: $preto;
  text-align: center;
  padding-bottom: 60px;
  padding-top: 60px;

  h3.title {
    display: inline-block;
    font-family: $inter;
    color: $branco;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.3rem;
    border: 2px solid $branco;
    border-radius: 50px;
    padding: 10px 20px;
    opacity: 0;
  }

  .mail {
    font-size: 2.5rem;
    font-weight: 200;
    font-style: italic;
    text-decoration: none;
    line-height: 140px;
    color: #fff;
    @media (min-width: $screen-sm-min) {
      font-size: 3.8rem;
    }
  }
}

// FOOTER
.footer {
  background: $preto;
  span {
    color: $branco;
    font-size: 13px;
    font-family: $inter;
    font-weight: 100;
    text-align: center;
    display: block;
    padding: 5px 0;
    &.tm {
      margin-bottom: 10px;
    }
  }
}

// SCROLLTIP
.scrolltip {
  opacity: 0;
  background-color: #fff;
  border-radius: 15px;
  bottom: 0;
  font-size: 0;
  left: 50%;
  padding: 16px 12px;
  position: fixed;
  transform: translate(-50%,-50%);
  transition: transform 1s cubic-bezier(.645,.045,.355,1);
  z-index: 8;
}
body.scroll .scrolltip {
  transform: translateY(200px);
}